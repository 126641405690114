import { memo } from 'react';
import { DjpApiConfigProvider } from 'dj-pages-react';
import { config } from '../config';

const apiConfig = {
  userIdentityGatewayBasePath: config.userIdentityGatewayPath,
};

const ApiConfigProvider = ({ children }): JSX.Element => {
  return <DjpApiConfigProvider apiConfig={apiConfig}>{children}</DjpApiConfigProvider>;
};

export default memo(ApiConfigProvider);
