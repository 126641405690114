'use client';
import { useEffect, useState } from 'react';
import { i18n, configureI18n } from '@paymentsense/dj-i18n';
import useLogger from './useLogger';
import { config } from '@/config';

export default function useI18nInitialized() {
  const [i18nInitialized, setI18nInitialized] = useState(false);
  const logger = useLogger();

  useEffect(() => {
    async function i18nConfiguration() {
      const timeoutId = setTimeout(() => {
        // In case the i18n is not initialized after 2 seconds, we set it to true anyway to avoid blocking the app
        setI18nInitialized(true);
      }, 2_000);

      const initializedCallback = () => {
        setI18nInitialized(true);
        clearTimeout(timeoutId);
      };

      i18n.on('initialized', initializedCallback);

      await configureI18n(null, config.supportedLanguages);

      return () => {
        i18n.off('initialized', initializedCallback);
      };
    }

    if (!i18nInitialized) {
      i18nConfiguration();
    }
  }, [logger, i18nInitialized]);

  return { i18nInitialized };
}
