import * as uaparser from 'ua-parser-js';
import { Analytics } from './analytics';
import { config } from '.';

export class UniversalProperties {
  public customerId: string;
  public businessGroup: string;
  public appVersion: string;
  public agent: Agent;
}

export class Agent {
  constructor(os: NameVersion, browser: NameVersion, device: Device) {
    this.os = os;
    this.browser = browser;
    this.device = device;
  }
  public os: NameVersion;
  public browser: NameVersion;
  public device: Device;
}

export class NameVersion {
  constructor(public name: string, public version: string) {}
}

export class Device {
  constructor(public model: string, public type: string, public vendor: string) {}
}

interface RudderStack {
  /**
   * The identify method is how you associate your users and their actions to a recognizable userId and traits.
   *
   * @param userId The database ID for the user.
   * @param traits A dictionary of traits you know about the user, like their email or name
   * @param options A dictionary of options.
   */
  identify(userId?: string, traits?: any, options?: any): void;

  /**
   * The track method lets you record any actions your users perform.
   *
   * @param event The name of the event you’re tracking.
   * @param properties A dictionary of properties for the event.
   * @param options A dictionary of options.
   */
  track(event: string, properties?: any, options?: any): void;
  /**
   * The page method lets you record page views on your website, along with optional extra information about the page being viewed.
   *
   * @param category The name of the category.
   * @param properties A dictionary of properties of the page.
   * @param options A dictionary of options.
   */
  page(category?: string, properties?: any, options?: any): void;
}

export class AnalyticsExporter implements Analytics {
  private readonly universalProperties: UniversalProperties;
  private get rudderAnalytics(): RudderStack {
    return (window as any).rudderanalytics;
  }

  constructor() {
    const parser = new uaparser.UAParser();

    const up = new UniversalProperties();
    up.appVersion = config.appVersion;
    up.businessGroup = config.businessGroup;
    up.agent = new Agent(
      new NameVersion(parser.getOS().name, parser.getOS().version),
      new NameVersion(parser.getBrowser().name, parser.getBrowser().version),
      new Device(parser.getDevice().model, parser.getDevice().type, parser.getDevice().vendor)
    );
    this.universalProperties = up;
  }

  public identify(contactId: string, customerId?: string): void {
    this.rudderAnalytics?.identify(contactId, {
      eventContext: { customerId },
      userId: contactId,
    });
  }

  public displayedFeature(category: string, action: string, label?: string, value?: any) {
    const properties = { category, action, label, value };
    this.track('displayedFeature', properties);
  }
  public clickedElement(category: string, action: string, label?: string, value?: any) {
    const properties = { category, action, label, value: value && JSON.stringify(value) };
    this.track('clickedElement', properties);
  }
  public clickedElementV2(category: string, action: string, label?: string, eventContext?: any) {
    const properties = { action, category, label, businessGroup: config.businessGroup };
    this.track('clickedElement', properties, eventContext);
  }
  public impression(category: string, action: string, label?: string, eventContext?: any) {
    const properties = { action, category, label, businessGroup: config.businessGroup };
    this.track('impression', properties, eventContext);
  }
  public hoveredElement(category: string, action: string, label?: string, eventContext?: any) {
    const properties = { action, category, label, businessGroup: config.businessGroup };
    this.track('hoveredOver', properties, eventContext);
  }
  public featureFlag(eventContext?: Array<any>): void {
    const properties = {
      action: 'receivedFeatureFlags',
      category: 'experimentation',
      businessGroup: config.businessGroup,
    };
    this.track('featureFlag', properties, { flags: eventContext });
  }
  public raiseError(category: string, action: string, errorMessage?: string, eventContext?: any) {
    const properties = { action, category, errorMessage, businessGroup: config.businessGroup };
    this.track('raisedError', properties, eventContext);
  }
  public choseOption(category: string, action: string, label?: string) {
    this.track('choseOption', { category, action, label });
  }
  public page(category: any, properties?: any, eventContext?: any) {
    this.pageWithStringContext(category, properties, JSON.stringify(eventContext));
  }
  public pageWithStringContext(category: any, properties?: any, eventContext?: string) {
    const enrichedProperties = {
      screenSize: `${window.innerWidth} x ${window.innerHeight}`,
      businessGroup: config.businessGroup,
      eventContext: eventContext,
      category,
    };
    const rudderanalytics = this.rudderAnalytics;
    rudderanalytics?.page({ ...enrichedProperties, ...properties });
  }
  public backgroundEvent(category: string, action: string, eventContext?: any) {
    const properties = { action, category, businessGroup: config.businessGroup };
    this.track('backgroundEvent', properties, eventContext);
  }

  public track(event: string, properties?: any, eventContext?: any): void {
    const rudderanalytics = this.rudderAnalytics;
    rudderanalytics?.track(event, {
      ...this.universalProperties,
      ...properties,
      eventContext: JSON.stringify(eventContext),
    });
  }
}
