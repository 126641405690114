interface CookieOptions {
  expires?: string | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  maxAge?: number;
  sameSite?: 'strict' | 'lax' | 'none';
}

export const getAllCookie = () => document.cookie;

export const getCookie = (name: string) => {
  let matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const setCookie = (name: string, value: string, options: CookieOptions = {}) => {
  options = {
    path: '/',
    // add other defaults here if necessary
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  try {
    document.cookie = updatedCookie;
  } catch (error) {
    // If the cookie size is too large, it will throw an error
    console.error('Error setting cookie', error); // eslint-disable-line
  }
};

export const deleteCookie = (name: string) => {
  setCookie(name, '', {
    maxAge: -1,
  });
};
