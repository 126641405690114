import { clsx } from '@dojo-engineering/styled-system';
import styles from './navItem.module.css';
import { i18n } from '@paymentsense/dj-i18n';

const NavItem = ({
  active,
  icon,
  sub,
  showNew,
  children,
}: {
  active?: boolean;
  sub?: boolean;
  icon: JSX.Element;
  showNew?: boolean;
  children: JSX.Element[] | JSX.Element;
}) => (
  <div className={styles.wrapper}>
    <div className={clsx(styles.item, { [styles.active]: active, [styles.sub]: sub })}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.text}>{children}</span>
      {showNew && <span className={styles.chip}>{i18n.t('global:global.badge.new_label')}</span>}
    </div>
  </div>
);

export default NavItem;
