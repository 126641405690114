import { createContext, memo, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { DjpErrorGeneric } from 'dj-pages-react';
import { CustomerRolePermission, Role, useCustomerRoleQuery, usePermissionsQuery } from '@/graphql/generated';
import { useCustomers } from '@/providers/customers';
import { RoutePermission } from '@/utils/routePermissions';
import useAuth from '@/hooks/useAuth';
import { LogoutModalReason } from '@/constants/login';
import { useInternalUsers } from '../internalUser';

interface PermissionsContextProps {
  loading: boolean;
  role?: Role;
  permissions?: CustomerRolePermission[];
}

export const PermissionsContext = createContext<PermissionsContextProps>({
  loading: false,
  role: undefined,
  permissions: undefined,
});

interface PermissionsProviderProps {
  children: React.ReactNode;
}

const PermissionsProvider = ({ children }: PermissionsProviderProps) => {
  const { customer } = useCustomers();
  const { customerId } = customer ?? {};
  const [role, setRole] = useState<Role>(null);
  const [permissions, setPermissions] = useState<CustomerRolePermission[]>(null);
  const { pathname } = useRouter();
  const { logout } = useAuth();
  const { isInternalUser, internalUserInfo, internalUserPermissions } = useInternalUsers();

  const {
    data: dataCustomerRole,
    loading: loadingCustomerRole,
    error: errorCustomerRole,
  } = useCustomerRoleQuery({
    variables: { customerId },
    skip: !customerId || isInternalUser,
  });

  useEffect(() => {
    // Reset permissions when customer changes so that we don't end up with stale permissions when switching customers
    if (!!internalUserInfo) {
      setRole(internalUserInfo?.role as unknown as Role);
      setPermissions(internalUserPermissions);
      return;
    }
    setRole(null);
    setPermissions(null);
  }, [internalUserInfo, internalUserPermissions]);

  useEffect(() => {
    if (!loadingCustomerRole && dataCustomerRole?.customerRole) {
      setRole(dataCustomerRole.customerRole);
    }
  }, [dataCustomerRole, loadingCustomerRole]);

  const {
    data: dataPermissions,
    loading: loadingPermissions,
    error: errorPermissions,
  } = usePermissionsQuery({
    variables: { customerId, role: dataCustomerRole?.customerRole },
    skip: !customerId || !dataCustomerRole?.customerRole || isInternalUser,
  });

  useEffect(() => {
    if (role && !loadingPermissions && dataPermissions?.customerRolePermissions?.length > 0) {
      setPermissions(dataPermissions.customerRolePermissions);
    }
  }, [role, dataPermissions, loadingPermissions]);

  useEffect(() => {
    if (permissions) {
      const permission = RoutePermission[pathname];
      // check if user has permission to access the page if defined by RoutePermission
      if (permission && !permissions.find((x) => x.name === permission && x.enabled)) {
        logout(LogoutModalReason.PERMISSION_ERROR);
      }
    }
  }, [logout, pathname, permissions]);

  const value: PermissionsContextProps = useMemo(
    () => ({
      loading: !role || !permissions,
      role: role,
      permissions: permissions,
    }),
    [permissions, role]
  );

  return errorCustomerRole || errorPermissions ? <DjpErrorGeneric /> : <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

export default memo(PermissionsProvider);

export const usePermissions = () => {
  return useContext(PermissionsContext);
};
