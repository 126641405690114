import { createContext, PropsWithChildren, memo, useState, Dispatch, SetStateAction, useMemo } from 'react';

export interface PageHeaderContextProps {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  navigationComponent?: JSX.Element;
  setNavigationComponent?: Dispatch<SetStateAction<JSX.Element>>;
}

export const PageHeaderContext = createContext<PageHeaderContextProps>(null);

const PageHeader = ({ children }: PropsWithChildren) => {
  const [title, setTitle] = useState<string>(null);
  const [navigationComponent, setNavigationComponent] = useState<JSX.Element>(null);
  const value = useMemo(() => ({ title, setTitle, navigationComponent, setNavigationComponent }), [title, navigationComponent]);

  return <PageHeaderContext.Provider value={value}>{children}</PageHeaderContext.Provider>;
};

export default memo(PageHeader);
