import { ReactElement, memo, useCallback } from 'react';
import * as styles from './accountMenuItem.css';
import useAnalytics from '@/hooks/useAnalytics';
import { DropdownMenu } from '@dojo-engineering/radix-ui';
import Link from 'next/link';
import { addBasePath } from '@/utils/routes';

type AnalyticKey = { category: string; action: string };

interface AccountMenuItemProps {
  route: string;
  children: string;
  analyticKey: AnalyticKey;
}

const AccountMenuItem = ({ route, children, analyticKey }: AccountMenuItemProps): ReactElement => {
  const { analytics } = useAnalytics();

  const handleClick = useCallback(() => {
    analytics.clickedElementV2(analyticKey?.category, analyticKey?.action);
  }, [analytics, analyticKey]);

  return (
    <DropdownMenu.Item className={styles.parentContainer} onClick={handleClick} asChild data-testid={`account-menu-item-${children}`}>
      <Link href={route} as={addBasePath(route)} className={styles.link}>
        <div className={styles.itemWrapper}>
          <div className={styles.item}>{children}</div>
        </div>
      </Link>
    </DropdownMenu.Item>
  );
};

export default memo(AccountMenuItem);
