import { memo, useCallback, useEffect } from 'react';
import idleTimer from 'idle-timer';
import useAuth from '@/hooks/useAuth';
import useAnalytics from '@/hooks/useAnalytics';
import { AnalyticsCategory } from '@/constants/analytics';
import { LogoutModalReason } from '@/constants/login';

const IDLE_TIME = 20 * 60 * 1000; //TODO: IDP - decreased the idle time to 1min from 20 min for testing

const IdleTimer = () => {
  const { logout } = useAuth();
  const { analytics } = useAnalytics();

  const handleIdle = useCallback(() => {
    analytics.clickedElementV2(AnalyticsCategory.signIn, 'autoLogOut');
    logout(LogoutModalReason.SESSION_TIMEOUT);
  }, [analytics, logout]);

  useEffect(() => {
    const timer = idleTimer({
      callback: handleIdle,
      idleTime: IDLE_TIME,
    });

    return () => {
      timer.destroy();
    };
  }, [handleIdle]);

  return null;
};

export default memo(IdleTimer);
