import { Routes, addBasePath } from '@/utils/routes';
import { Permissions } from './permissions';

const RoutePermissionPayment = {
  [addBasePath(Routes.payments)]: Permissions.paymentLinksView,
  [addBasePath(Routes.paymentsSettings)]: Permissions.paymentLinksView,
  [addBasePath(Routes.paymentsLocation)]: Permissions.paymentLinksView,
  [addBasePath(Routes.paymentsLocationSettings)]: Permissions.paymentLinksView,
};

const RoutePermissionCardMachine = {
  [addBasePath(Routes.cardMachines)]: Permissions.cardMachinesDetailsView,
  [addBasePath(Routes.cardMachinesProducts)]: Permissions.cardMachinesDetailsView,
  [addBasePath(Routes.cardMachinesTerminalOrderConfirmDetails)]: Permissions.cardMachinesOrderCreate,
  [addBasePath(Routes.cardMachinesTerminalOrderConfirmed)]: Permissions.cardMachinesOrderCreate,
  [addBasePath(Routes.cardMachinesTerminalOrderDeliveryAddress)]: Permissions.cardMachinesOrderCreate,
  [addBasePath(Routes.cardMachinesTerminalOrderDeliveryContact)]: Permissions.cardMachinesOrderCreate,
  [addBasePath(Routes.cardMachinesTerminalOrderFailed)]: Permissions.cardMachinesOrderCreate,
  [addBasePath(Routes.cardMachinesTerminalOrderReason)]: Permissions.cardMachinesOrderCreate,
  [addBasePath(Routes.cardMachinesTerminalOrderTradingLocations)]: Permissions.cardMachinesOrderCreate,
  [addBasePath(Routes.cardMachinesTerminalOrders)]: Permissions.cardMachinesDetailsView,
  [addBasePath(Routes.cardMachinesTerminalReplacementReason)]: Permissions.cardMachinesOrderCreate,
  [Routes.cardMachinesUpdateLocationSupervisorCode]: Permissions.cardMachinesAdminCodesEdit,
  [Routes.cardMachinesUpdateLocationSupervisorCodeFlow]: Permissions.cardMachinesAdminCodesEdit,
  [Routes.cardMachinesUpdateLocationSupervisorCodeFlowStep]: Permissions.cardMachinesAdminCodesEdit,
};

const RoutePermissionPciCompliance = {
  [addBasePath(Routes.pciCompliance)]: Permissions.pciComplianceView,
  [addBasePath(Routes.pciComplianceConfirm)]: Permissions.pciComplianceConfirm,
};

const RoutePermissionTransfers = {
  [addBasePath(Routes.transfers)]: Permissions.transfersAllView,
  [addBasePath(Routes.transfersReference)]: Permissions.transfersReferenceEdit,
};

export const RoutePermission = {
  // Home route redirect logic is handled in the Home page itself
  // [addBasePath(Routes.home)]: Permissions.transactionsAllView,

  [addBasePath(Routes.transactions)]: Permissions.transactionsAllView,

  ...RoutePermissionTransfers,

  // tools
  ...RoutePermissionCardMachine,
  ...RoutePermissionPayment,
  [addBasePath(Routes.tap)]: Permissions.tapDevicesDetailsView,
  [addBasePath(Routes.businessFunding)]: Permissions.businessFundingView,
  [addBasePath(Routes.insights)]: Permissions.businessInsightsView,

  // account
  [addBasePath(Routes.billing)]: Permissions.billingInvoiceView,
  [addBasePath(Routes.teams)]: Permissions.teamMembersView,
  [addBasePath(Routes.referAFriend)]: Permissions.referralAllView,
  ...RoutePermissionPciCompliance,
};
