import { memo } from 'react';
import { DjDrawer } from '@paymentsense/dj-components-react';
import styles from './navigation.module.css';
import InternalUserNav from './internalUserNav/internalUserNav';
import CustomerNav from './customerNav/customerNav';
import { useInternalUsers } from '@/providers/internalUser';
import Router from 'next/router';
import { Routes, addBasePath } from '@/utils/routes';
import useAnalytics from '@/hooks/useAnalytics';
import { navigationItemsAnalyticMap } from '@/config/analytics';

const Navigation = () => {
  const { isInternalUser } = useInternalUsers();
  const { analytics } = useAnalytics();

  const onBannerClick = () => {
    Router.push(Routes.home, addBasePath(Routes.home));
    analytics.clickedElementV2(navigationItemsAnalyticMap.Logo.category, navigationItemsAnalyticMap.Logo.action);
  };

  return (
    <DjDrawer
      animatedCloseMenu={false}
      bannerIcon="dojo_horizontal"
      onBannerClick={onBannerClick}
      closeButtonVariant="secondary"
      mobileFullWidth
      className={styles.drawer}
    >
      {isInternalUser ? <InternalUserNav /> : <CustomerNav />}
    </DjDrawer>
  );
};

export default memo(Navigation);
