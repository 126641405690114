import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig?.() ?? {};

export const config = {
  appVersion: publicRuntimeConfig?.appVersion,
  brazeEnabled: publicRuntimeConfig?.brazeEnabled === 'true',
  businessGroup: publicRuntimeConfig?.businessGroup,
  commitHash: publicRuntimeConfig?.commitHash,
  devPortalUrl: publicRuntimeConfig?.devPortalUrl,
  accountingRedirectUrl: publicRuntimeConfig?.accountingRedirectUrl,
  featureFlags: JSON.parse(publicRuntimeConfig?.featureFlags),
  graphqlUrl: publicRuntimeConfig?.graphqlUrl,
  host: publicRuntimeConfig.host,
  launchDarklyClientSideID: publicRuntimeConfig?.launchDarklyClientSideID,
  launchDarklySDKKey: publicRuntimeConfig?.launchDarklySDKKey,
  logRocketAppId: publicRuntimeConfig?.logRocketAppId,
  passwordPath: publicRuntimeConfig?.passwordPath,
  rudderstackKey: publicRuntimeConfig?.rudderstackKey,
  rudderstackUrl: publicRuntimeConfig?.rudderstackUrl,
  serviceEnvironment: publicRuntimeConfig?.serviceEnvironment,
  supportedLanguages: JSON.parse(publicRuntimeConfig?.supportedLanguages),
  userIdentityGatewayPath: publicRuntimeConfig?.userIdentityGatewayPath,
  osanoScriptUrl: publicRuntimeConfig?.osanoScriptUrl,
};
