export interface Analytics {
  identify: (customerId: string, email: string) => void;
  track: (event: string, properties?: any, eventContext?: any) => void;
  displayedFeature: (category: string, action: string, label?: string, value?: any) => void;
  clickedElement: (category: string, action: string, label?: string, value?: any) => void;
  clickedElementV2: (category: string, action: string, label?: string, eventContext?: any) => void;
  hoveredElement: (category: string, action: string, label?: string, eventContext?: any) => void;
  raiseError: (category: string, action: string, errorMessage?: string, eventContext?: any) => void;
  choseOption: (category: string, action: string, label?: string) => void;
  page: (category: any, properties?: any, eventContext?: any) => void;
  backgroundEvent: (category: string, action: string, eventContext?: any) => void;
}

export const navigationItemsAnalyticMap = {
  Logo: { category: 'navigation', action: 'selectedLogo' },
  Home: { category: 'navigation', action: 'selectedHomepage' },
  Transactions: { category: 'navigation', action: 'selectedTransactions' },
  Transfers: { category: 'navigation', action: 'selectedTransfers' },
  Payments: { category: 'toolsNavigation', action: 'selectedPayment' },
  Account: { category: 'navigation', action: 'openedAccountNav' },
  Tools: { category: 'navigation', action: 'openedToolNav' },
  Profile: { category: 'accountNavigation', action: 'selectedProfile' },
  'Location details': { category: 'accountNavigation', action: 'selectedLocations' },
  'Online payments': { category: 'toolsNavigation', action: 'selectedOnlinePayments' },
  'Account details': { category: 'accountNavigation', action: 'selectedAccountDetails' },
  'Refer a friend': { category: 'accountNavigation', action: 'selectedReferAFriend' },
  'Card machines': { category: 'toolsNavigation', action: 'selectedCardMachines' },
  'Pay by QR': { category: 'toolsNavigation', action: 'selectedPayByQr' },
  Billing: { category: 'accountNavigation', action: 'selectedBilling' },
  'Accounting integrations': { category: 'accountNavigation', action: 'selectedAccountIntegrations' },
  'Developer portal': { category: 'accountNavigation', action: 'selectedDeveloperPortal' },
  'Team members': { category: 'accountNavigation', action: 'selectedTeams' },
  'PCI compliance': { category: 'accountNavigation', action: 'selectedPciCompliance' },
  'My details': { category: 'accountNavigation', action: 'selectedMyDetails' },
  'Business funding': { category: 'toolsNavigation', action: 'selectedDojoAdvanceAccount' },
  Help: { category: 'navigation', action: 'selectedHelp' },
  'Privacy policy': { category: 'navigation', action: 'selectedPrivacyPolicy' },
  'Log out': { category: 'navigation', action: 'selectedLogOut' },
  Insights: { category: 'toolsNavigation', action: 'selectedInsights' },
  'Virtual queues &amp; bookings': { category: 'toolsNavigation', action: 'selectedQueuesBookings' },
  'Tap to Pay devices': { category: 'toolsNavigation', action: 'selectedTapToPay' }, // TBD ask analytics team how to define this
  Products: { category: 'toolsNavigation', action: 'selectedMarketplace' }, // TBD ask analytics team how to define this
  'Dojo Card': { category: 'toolsNavigation', action: 'selectedDojoCard' },
};
