import { LoggingProvider } from './loggingProvider';

// eslint-disable  no-console
export class ConsoleProvider implements LoggingProvider {
  setContext(uid: any) {}
  async debug(message: string, context?: any) {
    console.log('[DEBUG]', message, context);
  }
  async info(message: string, context?: any) {
    console.log('[INFO]', message, context);
  }
  async warn(message: string, context?: any) {
    console.warn('[WARN]', message, context);
  }
  async error(message: string, context?: any) {
    console.error('[ERROR]', message, context);
  }
  async exception(error: Error) {
    console.error('[EXCEPTION]', error);
  }
}
// eslint-enable  no-console
