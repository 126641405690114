import { i18n } from '@paymentsense/dj-i18n';
import { Routes } from '@/utils/routes';
import { DjBodyText, DjLink } from '@paymentsense/dj-components-react';
import { DjpInnerHtml } from 'dj-pages-react';

export default function AccountNotLinked() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DjBodyText>{i18n?.t(`login:login.account_not_linked.please_speak_to_your_manager_body`)}</DjBodyText>
      <DjBodyText>
        <DjpInnerHtml
          html={i18n?.t('login:login.account_not_linked.visit_help_centre_body', {
            link: '<slot name="link" />',
            interpolation: { escapeValue: false },
          })}
        >
          {/* The link below is not dynamic to market */}
          <DjLink slot="link" href={Routes.help} style={{ marginTop: '15px' }}>
            {i18n?.t('login:login.account_not_linked.help_centre_action')}
          </DjLink>
        </DjpInnerHtml>
      </DjBodyText>
    </div>
  );
}
