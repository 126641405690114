import { createContext, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Router from 'next/router';
import { DjpErrorGeneric } from 'dj-pages-react';
import { Customer, useCustomersQuery, usePendingInvitesQuery } from '@/graphql/generated';
import { LOCAL_STORAGE_CURRENT_CUSTOMER } from '@/utils/constants';
import { Routes, RoutesNoAuth, addBasePath } from '@/utils/routes';
import useAuth from '@/hooks/useAuth';
import { ServerError } from '@apollo/client';
import useAnalytics from '@/hooks/useAnalytics';
import { LogoutModalReason } from '@/constants/login';
import { useInternalUsers } from '../internalUser';
import { useInvites } from '../invite/InviteProvider';

interface CustomersContextProps {
  loading: boolean;
  customers?: Customer[];
  customer?: Customer;
  setCustomer: (customer: Customer) => void;
}

export const CustomersContext = createContext<CustomersContextProps>({
  loading: false,
  customers: [],
  customer: null,
  setCustomer: (_customer: Customer) => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

const ROUTE_CHANGE_COMPLETE = 'routeChangeComplete';

const CustomersProvider = ({ children }: AuthProviderProps) => {
  const { logout, user } = useAuth();
  const { analytics } = useAnalytics();
  const { isInternalUser, selectedCustomer } = useInternalUsers();
  const [checkInvites, setCheckInvites] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Customer>(null);
  const [customers, setCustomers] = useState<Customer[]>(null);
  const { loading: loadingCustomers, data, error } = useCustomersQuery({ skip: !user || isInternalUser });
  const [navigationInProgress, setNavigationInProgress] = useState(null);
  const { invites: searchInvites } = useInvites();

  useEffect(() => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (!loadingCustomers && data?.customers) {
      setCustomers(data?.customers as Customer[]);
    }
  }, [loadingCustomers, data]);

  useEffect(() => {
    const { customerMid, contactId, ...query } = Router.query;
    let currentCustomer = Number(localStorage.getItem(LOCAL_STORAGE_CURRENT_CUSTOMER) ?? '0');

    if (loadingCustomers || !customers || !user || isInternalUser) {
      return;
    }

    if (customers?.length < 1) {
      setCheckInvites(true);
      return;
    }

    if (customerMid && contactId === user.dojoId) {
      // For Deep Links switches to particular customer
      currentCustomer = customers.findIndex(({ customerId }) => customerId === customerMid);
    } else if (currentCustomer > customers.length - 1) {
      currentCustomer = 0;
    }

    localStorage.setItem(LOCAL_STORAGE_CURRENT_CUSTOMER, currentCustomer.toString());
    setCustomer(customers[currentCustomer] as Customer);

    if (customerMid && contactId && !RoutesNoAuth.some((route) => Router.pathname.startsWith(route)) && !navigationInProgress) {
      // In tandem with routeChangeComplete event handler prevents multiple redirections
      setNavigationInProgress(true);
      // Removes the customerMid and contactId from the query
      Router.push(Router.pathname, { pathname: addBasePath(Router.pathname), query }, { shallow: true });
    }
  }, [customers, customer, loadingCustomers, user, navigationInProgress, isInternalUser]);

  useEffect(() => {
    const cb = () => setNavigationInProgress(false);
    Router.events.on(ROUTE_CHANGE_COMPLETE, cb);
    return () => Router.events.off(ROUTE_CHANGE_COMPLETE, cb);
  }, []);

  useEffect(() => {
    if (isInternalUser) {
      user && analytics.identify(user['dojoId'] as string);
    } else {
      customer && user && analytics.identify(user['dojoId'] as string, customer.customerId);
    }
  }, [user, customer, analytics, isInternalUser]);

  useEffect(() => {
    if (!isInternalUser && searchInvites?.length === 0 && customers?.length === 0) {
      logout(LogoutModalReason.ACCOUNT_NOT_LINKED);
    }
  }, [customers, searchInvites, logout, isInternalUser]);

  useEffect(() => {
    // Do we need this as when the access token expires we should refresh it on 401
    if ((error?.networkError as ServerError)?.statusCode === 401) {
      logout(LogoutModalReason.TOKEN_EXPIRED);
    }
  }, [error, logout]);

  const handleCustomerChange = useCallback(
    (customer: Customer) => {
      if (isInternalUser) {
        setCustomer(customer);
      } else if (customers?.length > 0) {
        localStorage.setItem(
          LOCAL_STORAGE_CURRENT_CUSTOMER,
          customers
            .map((x) => x.customerId)
            .indexOf(customer?.customerId)
            .toString()
        );

        if (customer) {
          setCustomer(customer);
        }
      }
    },
    [customers, isInternalUser]
  );

  const value: CustomersContextProps = useMemo(
    () => ({
      loading: isInternalUser ? !customer : !customers || !customer,
      customers: customers,
      customer: customer,
      setCustomer: handleCustomerChange,
    }),
    [customer, customers, handleCustomerChange, isInternalUser]
  );
  return error ? <DjpErrorGeneric /> : <CustomersContext.Provider value={value}>{children}</CustomersContext.Provider>;
};

export default memo(CustomersProvider);

export const useCustomers = () => {
  return useContext(CustomersContext);
};
