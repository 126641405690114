import { memo, useMemo } from 'react';
import { DjBodyText, DjIconChevron_left, DjNav } from '@paymentsense/dj-components-react';
import styles from '../navigation.module.css';
import { navigationItemsAnalyticMap } from '@/config/analytics';
import { Routes, addBasePath } from '@/utils/routes';
import NavLink from '../navLink/navLink';
import { useCustomers } from '@/providers/customers';
import Router from 'next/router';
import { usePermissions } from '@/providers/permissions';
import NavigationItems from '../navItems/navItems';
import { DjpNavFooter } from 'dj-pages-react';
import useAuth from '@/hooks/useAuth';
import { useInternalUsers } from '@/providers/internalUser';
import { i18n } from '@paymentsense/dj-i18n';
import { LoadingPage } from '@/components/loadingPage/loadingPage';

const InternalUserNav = () => {
  const { loading: loadingCustomers, customer } = useCustomers();
  const { loading: loadingPermissions, permissions } = usePermissions();
  const { logout } = useAuth();
  const { customerMode } = useInternalUsers();

  const handleLogout = () => {
    localStorage.removeItem('currentCustomerId');
    logout();
  };

  const backToInternalUserList = () => {
    localStorage.removeItem('currentCustomerId');
    Router.push(Routes.internalUserHome, addBasePath(Routes.internalUserHome));
  };
  const loading = useMemo(
    () => !customer || !permissions || loadingCustomers || loadingPermissions,
    [customer, loadingCustomers, loadingPermissions, permissions]
  );

  const navStyle: any = {
    '--nav-footer-offset': `175px`,
  };

  return !customerMode ? (
    <DjNav className={styles.nav}>
      <NavLink analyticKey={navigationItemsAnalyticMap.Home} href={Routes.internalUserHome} text="internal_user:internal_user.overview.nav_item" />
      <div slot="footer" style={{ display: 'block', position: 'relative' }}>
        <DjpNavFooter isInternalUser={true} logoutCallback={handleLogout} />
      </div>
    </DjNav>
  ) : (
    <div>
      <div className={styles.backBtn} onClick={backToInternalUserList}>
        <DjIconChevron_left className={styles.backIcon} />
        {i18n.t('internal_user:internal_user.customer.back_to_list')}
      </div>
      <div style={navStyle}>
        <DjNav className={styles.nav}>
          <div className={styles.internalUserHeader}>
            {
              <DjBodyText size="small" colour="secondary">
                {i18n.t('internal_user:internal_user.customer.account')}
              </DjBodyText>
            }
            {customer?.registeredInformation?.registeredName}
          </div>
          {loading ? <LoadingPage count={5} /> : <NavigationItems />}
          <div slot="footer" style={{ display: 'block', position: 'relative' }}>
            <DjpNavFooter isInternalUser={true} logoutCallback={handleLogout} />
          </div>
        </DjNav>
      </div>
    </div>
  );
};

export default memo(InternalUserNav);
