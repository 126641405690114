import { GB } from '@/constants/markets';
import { useCustomers } from '@/providers/customers';
import { config } from '@/config';
import { checkCustomerIdInCustomerList } from '@/providers/accountingIntegrationsCustomers';

export default function useShowIntegrations(featureFlagValue: boolean) {
  const { customer } = useCustomers();

  if (!customer) return false;

  const { marketId } = customer;

  const isAccountingIntegrationsEnabled = config.featureFlags['CX-4444-accounting-integrations'] && marketId === GB;

  if (!featureFlagValue) {
    const customerInList = checkCustomerIdInCustomerList(customer.customerId);

    return isAccountingIntegrationsEnabled && customerInList;
  }

  return isAccountingIntegrationsEnabled;
}
