import { createContext, memo, useCallback, useEffect, useMemo } from 'react';
import { UserProfile, useUser } from '@auth0/nextjs-auth0/client';
import Router, { useRouter } from 'next/router';
import { ApiRoutes, RemoveBasePath, Routes, RoutesNoAuth, addBasePath } from '@/utils/routes';
import useLogger from '@/hooks/useLogger';
import { LogoutModalReason } from '@/constants/login';
import useAnalytics from '@/hooks/useAnalytics';
import { AnalyticsAction, AnalyticsCategory } from '@/constants/analytics';

export interface AuthContextProps {
  user?: UserProfile | null;
  loading: boolean;
  login: () => void;
  logout: (modalReason?: LogoutModalReason) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  loading: true,
  login: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { user, error, isLoading: loading } = useUser();
  const logger = useLogger();
  const { asPath, pathname } = useRouter();
  const { analytics } = useAnalytics();

  useEffect(() => {
    if (user) {
      const dojoId = user['dojoId'] as string;
      logger?.setContext(dojoId);
    }
  }, [user, logger]);

  useEffect(() => {
    if ((!loading && !user && !RoutesNoAuth.some((x) => pathname.includes(x))) || error) {
      Router.push(
        { pathname: Routes.login, query: pathname !== '/' ? { returnTo: RemoveBasePath(asPath) } : null },
        { pathname: addBasePath(Routes.login), query: pathname !== '/' ? { returnTo: RemoveBasePath(asPath) } : null }
      );
    }
  }, [asPath, error, loading, pathname, user]);

  const login = useCallback(() => {
    Router.push(ApiRoutes.login);
  }, []);

  const logout = useCallback(
    (logoutParam?: LogoutModalReason) => {
      analytics.clickedElement(AnalyticsCategory.account, AnalyticsAction.loggedOut);
      Router.push({ pathname: ApiRoutes.logout, ...(logoutParam && { query: { logoutParam } }) });
    },
    [analytics]
  );

  const value = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
    }),
    [loading, login, logout, user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default memo(AuthProvider);
