import { createContext, memo } from 'react';
import { usePhoneNumbersQuery } from '@/graphql/generated';
import { useCustomers } from '../customers';

export interface PhoneNumbers {
  CustomerService: string;
  YoulendPhoneNumber: string;
}

export interface PhoneNumbersContextType {
  loading: boolean;
  phoneNumbers: PhoneNumbers;
}

export const PhoneNumbersContext = createContext<PhoneNumbersContextType>({
  loading: false,
  phoneNumbers: {} as PhoneNumbers,
});

const PhoneNumbersProvider = ({ children }) => {
  const { loading: loadingCustomer, customer } = useCustomers();

  const { loading, data, error } = usePhoneNumbersQuery({ skip: loadingCustomer, variables: { marketId: customer?.marketId } });

  const phoneNumbers = data?.phoneNumbers?.reduce((accum, phoneNumber) => {
    accum[phoneNumber.id] = phoneNumber.value;
    return accum;
  }, {} as PhoneNumbers);

  return <PhoneNumbersContext.Provider value={{ loading, phoneNumbers }}>{children}</PhoneNumbersContext.Provider>;
};

export default memo(PhoneNumbersProvider);
