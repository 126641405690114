import { DjSkeleton } from '@paymentsense/dj-components-react';
import { Dictionary } from '@paymentsense/dj-components/dist/types/components/skeleton/skeleton';

type LoadingPageProps = {
  count?: number;
  theme?: React.CSSProperties;
};

export const LoadingPage = ({ count, theme }: LoadingPageProps) => {
  return <DjSkeleton count={count ?? 10} theme={theme as Dictionary} />;
};
