import router from 'next/router';
import useBraze from '@/hooks/useBraze';
import { Card } from '@/providers/braze';
import { DjBodyText, DjSubtitle } from '@paymentsense/dj-components-react';
import styles from './contentCard.module.css';
import { addBasePath } from '@/utils/routes';

export default function ContentCard({ contentCard }: { contentCard: Card }) {
  const { clickCard } = useBraze();
  const { description, imageUrl, title, url } = contentCard || {};

  const handleClickCard = () => {
    clickCard(contentCard);

    if (!url) {
      return;
    }
    router.push(url, addBasePath(url));

    document.dispatchEvent(new CustomEvent('closeDrawer', { bubbles: false }));
  };

  return (
    <div className={styles.wrapper} onClick={handleClickCard}>
      <picture>
        <img className={styles.image} alt="" src={imageUrl} />
      </picture>
      <div className={styles.context}>
        <DjSubtitle className={styles.subtitle} size="default" variant="default">
          {title}
        </DjSubtitle>
        <DjBodyText className={styles.bodyText} colour="secondary" size="small">
          {description}
        </DjBodyText>
      </div>
    </div>
  );
}
