import AccountNotLinked from '@/components/login/accountNotLinked/accountNotLinked';

export enum LogoutModalReason {
  LOGIN_ERROR = `loginError`,
  PERMISSION_ERROR = `permissionError`,
  SESSION_TIMEOUT = `sessionTimeout`,
  ACCOUNT_NOT_LINKED = `accountNotLinked`,
  TOKEN_EXPIRED = `tokenExpired`,
}

export const errorMessageMap: { [k: string]: { message?: string; header: string; body?: React.ReactNode } } = {
  [LogoutModalReason.LOGIN_ERROR]: {
    message: `login:login.welcome.error_sign_in`,
    header: `login:login.welcome.error_generic`,
  },
  [LogoutModalReason.PERMISSION_ERROR]: {
    message: `login:login.welcome.error_please_contact_manager`,
    header: `login:login.welcome.error_generic`,
  },
  [LogoutModalReason.SESSION_TIMEOUT]: {
    message: `login:login.welcome.inactive_logged_out`,
    header: `login:login.welcome.session_timeout`,
  },
  [LogoutModalReason.ACCOUNT_NOT_LINKED]: {
    header: `login:login.account_not_linked.page_heading`,
    body: <AccountNotLinked />,
  },
  [LogoutModalReason.TOKEN_EXPIRED]: {
    message: `login:login.error.expired_body`,
    header: `login:login.error.expired_title`,
  },
};
