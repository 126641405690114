import { useCallback } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

const useLaunchDarkly = () => {
  const ld = useLDClient();
  const flags = ld?.allFlags();
  const variation = ld?.variation;
  const getFlagValue = useCallback((flagKey: string) => flags?.[flagKey], [flags]);
  const waitUntilReady = ld?.waitUntilReady;

  return {
    getFlagValue,
    variation,
    waitUntilReady,
  };
};

export default useLaunchDarkly;
