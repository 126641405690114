import { createContext, memo, useEffect, useState } from 'react';
import { DjpLoggerProvider } from 'dj-pages-react';
import { LogRocketProvider } from './logging/logRocketProvider';
import { ConsoleProvider } from './logging/consoleProvider';
import { Logger } from './logging/logger';
import { config } from '@/config';

export const LoggerContext = createContext<Logger>(null);

const LoggerProvider = ({ children }): JSX.Element => {
  const [logger, setLogger] = useState(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // LogRocket should only be initialized when in the browser
    const localLogger = new Logger();

    // Enables LogRocket for all environments including local dev provided the LOGROCKET_APP_ID is set
    if (config.logRocketAppId) {
      localLogger.addLoggingProvider(new LogRocketProvider(config.logRocketAppId));
    }
    localLogger.addLoggingProvider(new ConsoleProvider());
    setLogger(localLogger);
    setIsClient(true);
  }, []);

  return (
    <LoggerContext.Provider value={logger}>
      {isClient && <DjpLoggerProvider logger={logger} />}
      {children}
    </LoggerContext.Provider>
  );
};

export default memo(LoggerProvider);
