import { LoggingProvider } from './loggingProvider';
import * as LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { config } from '@/config';
export class LogRocketProvider implements LoggingProvider {
  constructor(id: string) {
    LogRocket.init(id, {
      release: config.appVersion,
      mergeIframes: true,
      network: {
        requestSanitizer: (request) => {
          const url = request.url.toLowerCase();
          if (url.includes('firestore')) {
            return null;
          }
          if (url.includes('identitytoolkit')) {
            request.body = null;
          }
          if (!!request.headers.Authorization) {
            request.headers.Authorization = '';
          }
          if (!!request.headers.authorization) {
            request.headers.authorization = '';
          }
          return request;
        },
        responseSanitizer: (response) => {
          response.body = null;
          return response;
        },
      },
    });

    LogRocket.track('Build', {
      appVersion: config.appVersion,
      commitHash: config.commitHash,
      environment: config.serviceEnvironment,
    });

    setupLogRocketReact(LogRocket);
  }
  setContext(uid: any) {
    LogRocket.identify(uid);
  }
  async debug(message: string, context?: any) {
    LogRocket.debug({ message, context });
  }
  async info(message: string, context?: any) {
    LogRocket.info({ message, context });
  }
  async warn(message: string, context?: any) {
    LogRocket.warn({ message, context });
  }
  async error(message: string, context?: any) {
    LogRocket.error({ message, context });
  }
  async exception(error: Error) {
    LogRocket.captureException(error);
  }
}
