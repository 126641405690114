import { DjButtonIcon } from '@paymentsense/dj-components-react';
import { getCookie, setCookie } from '../../utils/browserCookies';
import { isIos } from '../../utils/deviceDetection';
import styles from './appBanner.module.css';
import { useEffect, useState } from 'react';
import { APP_BANNER_VIEWED_COOKIE, iosAppUrl, androidAppUrl } from '@/config/nativeApps';
import { addBasePath } from '@/utils/routes';

type AppBannerProps = {
  fixed?: boolean;
};

const AppBanner = ({ fixed }: AppBannerProps) => {
  const [showBanner, setShowBanner] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    setShowBanner(!getCookie(APP_BANNER_VIEWED_COOKIE));
    setUrl(isIos() ? iosAppUrl : androidAppUrl);
  }, []);

  const handleViewOrClose = () => {
    const dateNow = new Date();
    const dateInHours = dateNow.setHours(dateNow.getHours() + 1);
    setShowBanner(false);
    setCookie(APP_BANNER_VIEWED_COOKIE, 'true', { expires: `${dateInHours}` });
  };

  return (
    showBanner && (
      <>
        <div className={`${styles.container} ${fixed ? styles.fixed : ''}`}>
          <DjButtonIcon icon="close" className={styles.closeButton} onClick={handleViewOrClose} />
          <div className={styles.content}>
            <picture>
              <img src={addBasePath('/assets/images/dojo_app_icon.png')} alt="Dojo app" className={styles.icon} width={48} height={48} />
            </picture>
            <div>
              <h6 className={styles.header}>Dojo</h6>
              <span className={styles.message}>Get all your reporting here</span>
            </div>
          </div>
          <a className={styles.viewButton} onClick={handleViewOrClose} href={url} target="_blank">
            View
          </a>
        </div>
        {fixed && <div className={styles.fixedPlaceholder}></div>}
      </>
    )
  );
};

export default AppBanner;
