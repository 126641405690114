import { memo, useMemo } from 'react';
import { RoutesNoAuth } from '@/utils/routes';
import { useRouter } from 'next/router';

type Props = {
  children: JSX.Element;
  noAuthChildren: JSX.Element;
};

function ProtectedPages({ children, noAuthChildren }: Props): JSX.Element {
  const { pathname } = useRouter();
  const renderProtectedPages = useMemo(() => {
    return !RoutesNoAuth.some((route) => pathname === route);
  }, [pathname]);

  return renderProtectedPages ? children : noAuthChildren;
}

export default memo(ProtectedPages);
