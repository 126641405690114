import Navigation from '../navigation';
import { Header } from '../header/header';
import styles from './layout.module.css';
import AppBanner from '../appBanner/appBanner';
import { RoutesWithoutNavigationMenu } from '@/utils/routes';
import Router from 'next/router';
import DemoAccountBanner from '../demoAccountBanner/demoAccountBanner';
import { useCustomers } from '@/providers/customers';

export const Layout = ({ children }) => {
  const { customer } = useCustomers();

  if (RoutesWithoutNavigationMenu.some((route) => Router.pathname.startsWith(route))) {
    return children;
  }

  const showDemoAccountBanner = customer?.isSandbox === true;

  return (
    <div className={styles.layoutContainer}>
      <section className={styles.appBanner}>{showDemoAccountBanner ? <DemoAccountBanner /> : <AppBanner />}</section>
      <aside className={styles.aside}>
        <Navigation />
      </aside>
      <header className={styles.header}>
        <Header />
      </header>
      <main id="main" className={styles.content}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
