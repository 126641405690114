import { memo } from 'react';
import { DjpFeatureFlagsProvider } from 'dj-pages-react';
import { config } from '@/config';
import useLogger from '@/hooks/useLogger';

const FeatureFlagsProvider = ({ children }): JSX.Element => {
  let featureFlags = {};
  const logger = useLogger();

  try {
    featureFlags = config.featureFlags;
  } catch {
    logger.error('Error parsing feature flags');
  }

  return <DjpFeatureFlagsProvider featureFlags={featureFlags ?? {}}>{children}</DjpFeatureFlagsProvider>;
};

export default memo(FeatureFlagsProvider);
