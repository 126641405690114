'use client';
// import '@dojo-engineering/styled-system/dist/reset.css'; // Causes styles issues with modals
import '@dojo-engineering/styled-system/dist/dojo-bronze.css';
import '@dojo-engineering/radix-ui/dist/index.css'; // radix components
import '@dojo-engineering/react-datepicker/dist/index.css';
import '@dojo-engineering/react-drawer/dist/index.css';
import '@/styles/globals.css';
import React from 'react';
import Layout from '@/components/layout/layout';
import Providers from '@/providers';
import useStencilRouteChange from '@/hooks/useStencilRouteChange';
import useRudderInitialize from '@/hooks/useRudderInitialize';
import useI18nLoaded from '@/hooks/useI18nLoaded';
import useI18nInitialized from '@/hooks/useI18nInitialized';
import Seo from '@/components/seo/seo';
import { setupIonicReact } from '@ionic/react'; // this is needed for ionic components to work
import { DjpErrorGeneric } from 'dj-pages-react';
import { AppContext } from 'next/app';

setupIonicReact();

const MyApp = ({ Component, pageProps, nonce }) => {
  const { i18nLoaded, error: i18nError } = useI18nLoaded();
  const { i18nInitialized } = useI18nInitialized();
  useStencilRouteChange();
  useRudderInitialize();

  if (i18nError && !i18nLoaded) {
    return (
      <>
        <Seo />
        <DjpErrorGeneric />
      </>
    );
  }

  return (
    <>
      <Seo />
      {i18nLoaded && i18nInitialized && (
        <Providers nonce={nonce}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Providers>
      )}
    </>
  );
};

// this method is an opt out from static optimization
// get the nonce value from headers
MyApp.getInitialProps = async (Context: AppContext) => {
  const nonce = Context.ctx.req?.headers?.['x-nonce'] as string | undefined;

  return {
    props: {} as never,
    nonce,
  };
};

export default MyApp;
