
      export default {
  "possibleTypes": {
    "AcceptInviteResponse": [
      "AcceptInviteError",
      "Invite"
    ],
    "AccountVerificationResponse": [
      "AccountVerification",
      "AccountVerificationError"
    ],
    "CalculateFlexOffersResponse": [
      "BusinessFundingState",
      "CalculateFlexOffersError"
    ],
    "CancelGroupTerminalReturnResponse": [
      "FailedCancelGroupTerminalReturn",
      "GroupTerminalReturnCancelled"
    ],
    "CancelInviteResponse": [
      "CancelInviteError",
      "Invite"
    ],
    "CancelTerminalReturnResponse": [
      "FailedCancelTerminalReturn",
      "TerminalReturnCancelled"
    ],
    "CardMachineSupervisorCodeStatusResponse": [
      "CardMachineSupervisorCodeStatus",
      "RequestError"
    ],
    "CloseTapDeviceResponse": [
      "FailedCloseTapDevice",
      "TapDeviceClosed"
    ],
    "CollectPreAuthResponse": [
      "FailedTransactionOperation",
      "PreAuthCollected"
    ],
    "ConfirmPciComplianceResponse": [
      "FailedPCIUpdate",
      "FailedUpdate",
      "PCICompliance",
      "TimeoutPCIUpdate",
      "TimeoutUpdate"
    ],
    "CreateInviteResponse": [
      "CreateInviteError",
      "Invite"
    ],
    "CreateTapDeviceOrderResponse": [
      "FailedCreateTapDeviceOrder",
      "TapDeviceOrderCreated"
    ],
    "CreateTerminalOrderResponse": [
      "FailedCreateTerminalOrder",
      "TerminalOrderCreated"
    ],
    "CreateTerminalReturnResponse": [
      "FailedCreateTerminalReturn",
      "TerminalReturnCreated"
    ],
    "EmailTapTransactionResponse": [
      "EmailTapTransactionError",
      "TapTransactionEmail"
    ],
    "Error": [
      "EmailTapTransactionError",
      "FailedCancelGroupTerminalReturn",
      "FailedCancelTerminalReturn",
      "FailedCloseTapDevice",
      "FailedCreateTapDeviceOrder",
      "FailedCreateTerminalOrder",
      "FailedCreateTerminalReturn",
      "FailedGetPciSignedUrl",
      "FailedLocationNicknameUpdate",
      "FailedLocationSearch",
      "FailedPCIUpdate",
      "FailedUpdate",
      "RemoveContactFailed",
      "TimeoutLocationNicknameUpdate",
      "TimeoutPCIUpdate",
      "TimeoutUpdate"
    ],
    "LocationSearchResponse": [
      "FailedLocationSearch",
      "LocationSearchResult"
    ],
    "LocationSupervisorCodeStatusResponse": [
      "LocationSupervisorCodeStatus",
      "RequestError"
    ],
    "OperationOutcome": [
      "PreAuthCollected",
      "PreAuthReleased",
      "TransactionF2fRefunded",
      "TransactionRefunded",
      "TransactionReversed"
    ],
    "OperationOutcomeV2": [
      "TransactionV2F2fRefunded"
    ],
    "PciSignedUrlResponse": [
      "FailedGetPciSignedUrl",
      "PciSignedUrl"
    ],
    "ReleasePreAuthResponse": [
      "FailedTransactionOperation",
      "PreAuthReleased"
    ],
    "RemoveContactResponse": [
      "RemoveContactAccepted",
      "RemoveContactFailed"
    ],
    "SubmitBusinessFundingResponse": [
      "BusinessFundingState",
      "SubmitBusinessFundingError"
    ],
    "TransactionF2fRefundedResponse": [
      "FailedTransactionOperation",
      "TransactionF2fRefunded"
    ],
    "TransactionRefundedResponse": [
      "FailedTransactionOperation",
      "TransactionRefunded"
    ],
    "TransactionReversedResponse": [
      "FailedTransactionOperation",
      "TransactionReversed"
    ],
    "TransactionV2F2fRefundedResponse": [
      "FailedTransactionV2Operation",
      "TransactionV2F2fRefunded"
    ],
    "UpdateCardMachineLocationSupervisorCodeResponse": [
      "CardMachineLocationSupervisorCodeUpdated",
      "FailedUpdate"
    ],
    "UpdateCardMachineNameResponse": [
      "CardMachineNameUpdated",
      "FailedUpdate",
      "TimeoutUpdate"
    ],
    "UpdateCardMachineSupervisorCodeResponse": [
      "CardMachineSupervisorCodeUpdated",
      "FailedUpdate"
    ],
    "UpdateLocationNicknameResponse": [
      "FailedLocationNicknameUpdate",
      "LocationNicknameUpdated",
      "TimeoutLocationNicknameUpdate"
    ],
    "ValuePropContent": [
      "ValuePropFaqs",
      "ValuePropPricing",
      "ValuePropTestimonials",
      "ValuePropUsps"
    ]
  }
}
    