import { memo } from 'react';
import { DjpMarketProvider } from 'dj-pages-react';
import { useCustomers } from './customers';

const MarketProvider = ({ children }): JSX.Element => {
  const { customer } = useCustomers();
  // fall back to GB if customer is not loaded yet
  const marketId = customer?.marketId ?? 'GB';

  return <DjpMarketProvider marketId={marketId}>{children}</DjpMarketProvider>;
};

export default memo(MarketProvider);
