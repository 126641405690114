import { useCallback, useMemo } from 'react';
import Router from 'next/router';
import { i18n } from '@paymentsense/dj-i18n';
import { DjNavSetting } from '@paymentsense/dj-components-react';
import { useCustomers } from '@/providers/customers';
import { DjNavSettingCustomEvent } from '@paymentsense/dj-components/dist/types/components';
import { Routes, addBasePath } from '@/utils/routes';
import { LoadingPage } from '../loadingPage/loadingPage';
import { useInvites } from '@/providers/invite/InviteProvider';

export const SwitchAccount = () => {
  const { customers, customer, setCustomer, loading } = useCustomers();
  const { acceptedInvites } = useInvites();

  const options = useMemo(
    () =>
      customers?.map((customer) => {
        return { label: customer.registeredInformation.registeredName, value: customer.customerId };
      }) ?? [],
    [customers]
  );

  const pendingOptions = useMemo(
    () =>
      acceptedInvites
        ?.filter((invite) => !customers?.some((customer) => customer.customerId === invite?.customer?.customerId))
        .map((invite) => {
          return {
            label: invite?.customer.registeredInformation.registeredName,
            value: invite.inviteId,
            secondText: i18n.t('invites:invites.invite.switch_account_status'),
          };
        }) ?? [],
    [acceptedInvites, customers]
  );
  const value = useMemo(() => customer?.customerId ?? '', [customer?.customerId]);

  const handleOnChange = useCallback(
    async (event: DjNavSettingCustomEvent<string>) => {
      const c = customers.find((customer) => customer?.customerId === event?.detail);

      if (customer?.customerId !== c.customerId) {
        await Router.replace(Routes.home, addBasePath(Routes.home));
      }

      setCustomer(c);
    },
    [customer?.customerId, customers, setCustomer]
  );

  if (loading || options.length === 0) {
    return <LoadingPage />;
  }

  return (
    <DjNavSetting
      header={i18n.t('global:global.navigation.switch_account_label')}
      value={value}
      options={options}
      onNavSettingValueChanged={handleOnChange}
      pendingOptions={pendingOptions}
    />
  );
};
