import { PRODUCT_IDS } from '../../../dj-pages/src/constants/productConstants';

export const basePath = '/';

const normalize = (path: string) => {
  return path.replace('//', '/');
};

/**
 * @deprecated No longer required since APPL-274 merged
 */
export const addBasePath = (path: string) => {
  return normalize(`${basePath}${path}`);
};

export const RemoveBasePath = (path: string) => {
  return normalize(path.replace(basePath, ''));
};

export const apiBasePath = addBasePath('/api');

const RoutesPayments = {
  payments: `/payments`,
  paymentsSettings: `/payments/settings`,
  paymentsLocation: `/payments/[locationId]`,
  paymentsLocationSettings: `/payments/[locationId]/settings`,
};

const RoutesCardMachine = {
  cardMachines: `/card-machines`,
  cardMachinesProducts: `/card-machines/card-machines-products`,
  cardMachinesTerminalOrderConfirmDetails: `/card-machines/terminal-order-confirm-details`,
  cardMachinesTerminalOrderConfirmed: `/card-machines/terminal-order-confirmed`,
  cardMachinesTerminalOrderDeliveryAddress: `/card-machines/terminal-order-delivery-address`,
  cardMachinesTerminalOrderDeliveryContact: `/card-machines/terminal-order-delivery-contact`,
  cardMachinesTerminalOrderFailed: `/card-machines/terminal-order-failed`,
  cardMachinesTerminalOrderReason: `/card-machines/terminal-order-reason`,
  cardMachinesTerminalOrderTradingLocations: `/card-machines/terminal-order-trading-locations`,
  cardMachinesTerminalOrders: `/card-machines/terminal-orders`,
  cardMachinesTerminalReplacementReason: `/card-machines/terminal-replacement-reason`,
  cardMachinesReplacementList: `/card-machines/terminal-replacement-list/`,
  cardMachinesCollectionDetails: `/card-machines/collection-details/`,
  cardMachinesReplacementCollectionConfirmation: `/card-machines/collection-confirmation/`,
  cardMachinesReplacementCollectionConfirmed: `/card-machines/collection-confirmed/`,
  cardMachinesReplacementNoCarrierRoute: `/card-machines/terminal-replacement-no-carrier`,
  cardMachinesActivation: `/card-machines/activation`,
  cardMachinesUpdateLocationSupervisorCode: `/card-machines/update-location-supervisor-code`,
  cardMachinesUpdateLocationSupervisorCodeFlow: `/card-machines/update-location-supervisor-code/[locationId]`,
  cardMachinesUpdateLocationSupervisorCodeFlowStep: `/card-machines/update-location-supervisor-code/[locationId]/[[...step]]`,
};

const RoutesPciCompliance = {
  pciCompliance: `/pci-compliance`,
  pciComplianceConfirm: `/pci-compliance/confirm`,
};

const RoutesTransfers = {
  transfers: `/transfers`,
  transfersReference: '/transfers/transfer-reference',
};

const RoutesBusinessFundingVerification = {
  verificationSuccess: '/business-funding-verification/success',
  verificationError: '/business-funding-verification/error',
};

export const Routes = {
  home: `/`,

  internalUserHome: '/internal-user',
  transactions: `/transactions`,

  // tools
  ...RoutesCardMachine,
  tap: `/tap-devices`,
  onlinePayments: `/online-payments`,
  newOnlinePayments: `/products/${PRODUCT_IDS.OnlineCheckout}`,
  ...RoutesPayments,
  insights: `/insights`,
  businessFunding: `/business-funding`,
  queuesBooking: `/queues-bookings`,
  newQueuesBooking: `/products/${PRODUCT_IDS.RMS}`,
  newQueuesBookingOrder: `/products/product-order/${PRODUCT_IDS.RMS}`,
  onlineCheckoutOrder: `/products/product-order/${PRODUCT_IDS.OnlineCheckout}`,
  payByQr: `/pay-by-qr`,
  dojoCard: `/dojo-card`,

  ...RoutesTransfers,

  // account
  myDetails: `/my-details`,
  referAFriend: `/refer-a-friend`,
  accountDetails: `/account-details`,
  locations: `/locations`,
  integrations: `/integrations`,
  teams: `/teams`,
  billing: `/billing`,
  ...RoutesPciCompliance,

  invite: `/invite`,

  register: `/register`,
  login: `/login`,
  loginCallback: `/login-callback`,

  notLinked: `/not-linked`,
  notFound: `/404`,

  help: 'https://support.dojo.tech/hc/en-gb',
};

export const ApiRoutes = {
  login: `${apiBasePath}/auth/login`,
  logout: `${apiBasePath}/auth/logout`,
  profile: `${apiBasePath}/auth/me`,
  token: `${apiBasePath}/auth/token`,
  graphql: `${apiBasePath}/graphql`,
  graphqlUploadFile: `${apiBasePath}/graphql/upload-file`,
};

export const RoutesNoAuth = [
  Routes.register,
  Routes.login,
  Routes.register,
  Routes.notFound,
  Routes.cardMachinesActivation,
  RoutesBusinessFundingVerification.verificationError,
  RoutesBusinessFundingVerification.verificationSuccess,
];

export const RoutesWithoutNavigationMenu = [
  Routes.register,
  Routes.login,
  Routes.notLinked,
  Routes.notFound,
  Routes.invite,
  Routes.loginCallback,
  Routes.cardMachinesActivation,
  RoutesBusinessFundingVerification.verificationError,
  RoutesBusinessFundingVerification.verificationSuccess,
];
