import { LoggingProvider } from './loggingProvider';

export class Logger {
  private readonly providers = new Array<LoggingProvider>();

  public addLoggingProvider(provider: LoggingProvider) {
    if (provider) {
      this.providers.push(provider);
    }
  }

  public setContext(uid: any) {
    this.providers.forEach((provider) => provider.setContext(uid));
  }

  public debug(message: string, context?: any) {
    this.providers.forEach((provider) => provider.debug(message, context));
  }

  public info(message: string, context?: any) {
    this.providers.forEach((provider) => provider.info(message, context));
  }

  public warn(message: string, context?: any) {
    this.providers.forEach((provider) => provider.warn(message, context));
  }

  public error(message: string, context?: any) {
    this.providers.forEach((provider) => provider.error(message, context));
  }

  public exception(error: Error) {
    this.providers.forEach((provider) => provider.exception(error));
  }
}
