import { DropdownMenu } from '@dojo-engineering/radix-ui';
import { AccountCircle, ChevronDown } from '@dojo-engineering/react-icons';
import { clsx, sprinkles, tokens } from '@dojo-engineering/styled-system';
import { ReactElement } from 'react';
import { i18n } from '@paymentsense/dj-i18n';
import * as styles from './accountMenuHeader.css';
import { colorsBronze as colors } from '@dojo-engineering/design-tokens';

interface AccountHeaderProps {
  open: boolean;
}

export const AccountMenuHeader = ({ open }: AccountHeaderProps): ReactElement => {
  return (
    <DropdownMenu.Trigger asChild>
      <div className={clsx(styles.parentContainer, sprinkles({ display: 'flex', alignItems: 'center' }))}>
        <AccountCircle width={24} height={24} color={colors.blackAlpha60} />
        <div className={styles.header}>{i18n.t('global:global.navigation.account_menu_item')}</div>
        <button className={clsx(styles.button, sprinkles({ display: 'flex', alignItems: 'center' }))}>
          <ChevronDown
            style={{ opacity: '0.6', alignSelf: 'flex-end' }}
            className={clsx(DropdownMenu.dropdownTriggerContextMenuStyles, open ? styles.rotate : styles.rotateBase)}
          />
        </button>
      </div>
    </DropdownMenu.Trigger>
  );
};
