import { useEffect } from 'react';
import Router from 'next/router';
import { addBasePath, basePath } from '@/utils/routes';

const useStencilRouteChange = () => {
  useEffect(() => {
    const callback = (event: any) => {
      let route: string = event.detail.path;
      const queryParams = event.detail.queryParams;

      if (!route.startsWith('/')) {
        route = '/' + route;
      }
      Router.push(
        {
          pathname: route,
          query: queryParams,
        },
        {
          pathname: route.startsWith(basePath) ? route : addBasePath(route),
          query: queryParams,
        }
      );
    };
    window.addEventListener('stencilRouteChange', callback);

    return () => {
      window.removeEventListener('stencilRouteChange', callback);
    };
  }, []);
};

export default useStencilRouteChange;
