import { createContext, memo, useEffect, useState } from 'react';
import { useCustomers } from '@/providers/customers';
import { BrazeClient, useBrazeApiKeyQuery } from '@/graphql/generated';
import useAuth from '@/hooks/useAuth';
import { config } from '@/config';
import { BRAZE_CONTENT_CARDS } from '@/utils/constants';
import { DjpBrazeProvider } from 'dj-pages-react';

export interface Card {
  id?: string;
  imageUrl?: string;
  viewed?: boolean;
  expiresAt?: Date;
  title?: string;
  description?: string;
  created?: Date;
  extras?: any;
  url?: string;
}
export interface AppBoy {
  logCustomEvent(name: string, properties?: object): boolean;
  getContentCards(): void | boolean;
  accountCards: Card[];
  homepageCards: Card[];
  dismissCard(card: any): void;
  clickCard(card: any): void;
  viewCard(card: any): boolean;
  authenticated: boolean;
  initialized: boolean;
  contentCards: { [section: string]: Card[] };
}

export const BrazeContext = createContext<AppBoy>({
  logCustomEvent: () => false,
  getContentCards: () => {},
  accountCards: [],
  homepageCards: [],
  dismissCard: () => {},
  clickCard: () => {},
  viewCard: () => false,
  authenticated: false,
  initialized: false,
  contentCards: {},
});

const BrazeProvider = ({ nonce, children }) => {
  const { customer } = useCustomers();
  const { user } = useAuth();
  const userId = user?.dojoId as string;
  const [appBoy, setAppBoy] = useState<AppBoy>(null);
  const [homepageCards, setHomepageCards] = useState([]);
  const [accountCards, setAccountCards] = useState([]);
  const [contentCardsBySection, setContentCardsBySection] = useState<{ [section: string]: Card[] }>({});
  const { data, error } = useBrazeApiKeyQuery({
    variables: {
      marketId: customer?.marketId ?? 'GB',
      client: BrazeClient.Web,
    },
    skip: !customer || !user || !config.brazeEnabled,
  });

  useEffect(() => {
    if (!data || !userId || appBoy) {
      return;
    }

    const brazeApiKey = data.brazeApiKey;

    // braze sdk need to be imported in runtime
    // if is imported in the start of the file will fail to detect the browser data
    import('@braze/web-sdk').then(
      ({
        initialize,
        openSession,
        logCustomEvent,
        logCardDismissal,
        logContentCardClick,
        changeUser,
        requestContentCardsRefresh,
        getCachedContentCards,
        logContentCardImpressions,
        automaticallyShowInAppMessages,
        subscribeToContentCardsUpdates,
      }) => {
        const initialized = initialize(brazeApiKey.key, {
          baseUrl: brazeApiKey.endpoint,
          allowUserSuppliedJavascript: true,
          enableLogging: false,
          enableSdkAuthentication: true,
          sessionTimeoutInSeconds: 1200,
          contentSecurityNonce: nonce,
        });
        automaticallyShowInAppMessages();
        subscribeToContentCardsUpdates((contentCards) => {
          const { cards } = contentCards?.cards.length > 0 ? contentCards : getCachedContentCards();
          setAccountCards(cards.filter((card) => card?.extras?.section === BRAZE_CONTENT_CARDS.ACCOUNT));
          setHomepageCards(cards.filter((card) => card?.extras?.section === BRAZE_CONTENT_CARDS.HOMEPAGE_SUGGESTED));

          const contentCardsBySection = cards.reduce((contentCardsBySection, card) => {
            if (!card?.extras?.section) {
              return contentCardsBySection;
            }

            if (!contentCardsBySection[card.extras.section]) {
              contentCardsBySection[card.extras.section] = [];
            }

            contentCardsBySection[card.extras.section].push(card);
            return contentCardsBySection;
          }, {});

          setContentCardsBySection(contentCardsBySection);
        });
        if (userId) {
          changeUser(userId, brazeApiKey.brazeApiKeyJWT);
          openSession();
        }
        setAppBoy({
          initialized: initialized,
          logCustomEvent: logCustomEvent,
          dismissCard: logCardDismissal,
          accountCards: [],
          homepageCards: [],
          contentCards: {},
          getContentCards: (): void | boolean => {
            if (!initialized) {
              return false;
            }
            requestContentCardsRefresh();
          },
          clickCard: logContentCardClick,
          viewCard: (card: any) => logContentCardImpressions([card]),
          authenticated: !!userId,
        });
      }
    );
  }, [data, appBoy, userId, nonce]);

  if (!config.brazeEnabled || !userId || !data || error || !appBoy) {
    // ignore braze
    return children;
  }

  return (
    <BrazeContext.Provider value={{ ...appBoy, accountCards, homepageCards }}>
      <DjpBrazeProvider appboy={{ ...appBoy, contentCards: contentCardsBySection }}>{children}</DjpBrazeProvider>
    </BrazeContext.Provider>
  );
};

export default memo(BrazeProvider);
