import { ApiRoutes } from '@/utils/routes';
import { ApolloClient, ApolloLink, ApolloProvider as BaseApolloProvider, DefaultOptions, InMemoryCache } from '@apollo/client';
import { FieldFunctionOptions, InMemoryCacheConfig, ServerError } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { createUploadLink } from 'apollo-upload-client';
import { sha256 } from 'crypto-hash';
import { DjpApolloProvider } from 'dj-pages-react';
import router from 'next/router';
import { Context, ReactNode, createContext, memo } from 'react';
import {
  PaymentsSearchResultPaged,
  SearchPaymentsQueryVariables,
  SearchTransactionsQueryVariables,
  TransactionDateGroup,
  TransactionSearchResult,
} from 'superpay-graphql-client';
import { StrictTypedTypePolicies } from 'superpay-graphql-client/dist/apollo-helpers';
import introspectionResult from 'superpay-graphql-client/dist/fragment-matcher';

export function mergePaymentsSearchResults(
  existing: PaymentsSearchResultPaged,
  incoming: PaymentsSearchResultPaged,
  { variables }: FieldFunctionOptions
): PaymentsSearchResultPaged {
  // do not merge if not fetch more
  if (!(variables as SearchPaymentsQueryVariables)?.afterCursor) {
    return incoming;
  }

  return {
    cursors: incoming.cursors,
    payments: [...existing.payments, ...incoming.payments],
  };
}

const toKeyValues = (groups: TransactionDateGroup[]): { [date: string]: TransactionDateGroup } =>
  groups.reduce((acc, curr) => Object.assign(acc, { [curr.date]: { ...curr } }), {});

function mergeTransactionGroups(prevGroups: TransactionDateGroup[], nextGroups: TransactionDateGroup[]): TransactionDateGroup[] {
  const prev = toKeyValues(prevGroups);

  for (const nextGroup of nextGroups) {
    // if group already exists in previous result - append transaction, otherwise add new group
    if (prev[nextGroup.date]) {
      prev[nextGroup.date].transactions = prev[nextGroup.date].transactions.concat(nextGroup.transactions);
    } else {
      Object.assign(prev, { [nextGroup.date]: nextGroup });
    }
  }

  return Object.values(prev);
}

export function mergeTransactionSearchResults(existing: TransactionSearchResult, incoming: TransactionSearchResult, { variables }: FieldFunctionOptions) {
  // do not merge if not fetch more
  if (!(variables as SearchTransactionsQueryVariables).search.afterCursor) {
    return incoming;
  }

  return {
    cursors: incoming.cursors,
    searchTotals: incoming.searchTotals,
    transactionDateGroups: !existing ? incoming.transactionDateGroups : mergeTransactionGroups(existing.transactionDateGroups, incoming.transactionDateGroups),
  };
}

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'no-cache',
  },
};

const typePolicies: StrictTypedTypePolicies & any = {
  // ...typePolicies as defined in the original Angular code
  Location: {
    keyFields: ['locationId'],
  },
  CardMachine: {
    keyFields: ['terminalId'],
  },
  PaymentsSearchResult: {
    keyFields: ['paymentIntentId'],
  },
  ReceiptConfigurationResponse: {
    keyFields: ['id'],
  },
  Invite: {
    keyFields: ['inviteId'],
  },
  Query: {
    fields: {
      searchTransactions: {
        keyArgs: false,
        merge: mergeTransactionSearchResults,
      },
      payments: {
        keyArgs: false,
        merge: mergePaymentsSearchResults,
      },
    },
  },
};

const cacheOption: InMemoryCacheConfig = {
  typePolicies,
  possibleTypes: introspectionResult.possibleTypes,
};

const ApolloClientContext: Context<ApolloClient<any> | undefined> = createContext<ApolloClient<any> | undefined>(undefined);

interface ApolloProviderProps {
  children: ReactNode;
}

const uploadFileQueries = ['UploadFile', 'uploadBusinessFundingDocument'];

const uploadLink: ApolloLink = createUploadLink({
  uri: ({ operationName }) => (uploadFileQueries.includes(operationName) ? ApiRoutes.graphqlUploadFile : ApiRoutes.graphql),
});

const persistedLink: ApolloLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: false });

const disablePersistedQueriesForUploadsLink = new ApolloLink((operation, forward) => {
  if (uploadFileQueries.includes(operation.operationName)) {
    operation.setContext({
      http: {
        includeQuery: true,
        includeExtensions: false,
      },
    });
  }
  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  const error = networkError as ServerError;
  if (error?.statusCode === 401 && (error.result as { redirectTo: string }).redirectTo === 'login') {
    router.push((error.result as { path: string }).path);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([persistedLink, disablePersistedQueriesForUploadsLink, errorLink, uploadLink]),
  cache: new InMemoryCache(cacheOption),
  defaultOptions,
});

function ApolloProvider({ children }: ApolloProviderProps): JSX.Element {
  return (
    <BaseApolloProvider client={client}>
      <DjpApolloProvider client={client as any} />
      <ApolloClientContext.Provider value={client}>{children}</ApolloClientContext.Provider>
    </BaseApolloProvider>
  );
}

export default memo(ApolloProvider);
