import { useRouter } from 'next/router';
import Link from 'next/link';

import { DjButtonIcon } from '@paymentsense/dj-components-react';

import { config } from '@/config';
import { Routes, addBasePath } from '@/utils/routes';
import { Permissions } from '@/utils/permissions';

import { usePermissions } from '@/providers/permissions';

export const TransferReference = () => {
  const router = useRouter();
  const { permissions } = usePermissions();

  const showTransferReference =
    permissions &&
    permissions.some((x) => x.name === Permissions.transfersReferenceEdit && x.enabled === true) &&
    router.pathname === addBasePath(Routes.transfers) &&
    config.featureFlags['CX-2798-transfers-reference'] === true;

  if (!showTransferReference) {
    return <></>;
  }

  return (
    <Link href={Routes.transfersReference} as={addBasePath(Routes.transfersReference)}>
      <div>
        <DjButtonIcon className="transfers-settings" aria-label="transfers settings button" icon="cog" size="extra-small" />
      </div>
    </Link>
  );
};
