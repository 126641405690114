import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { memo } from 'react';

import { i18n } from '@paymentsense/dj-i18n';

import useI18nLoaded from '@/hooks/useI18nLoaded';
import { addBasePath } from '@/utils/routes';
import useI18nInitialized from '@/hooks/useI18nInitialized';

const Seo = () => {
  const { locale } = useRouter();
  const { i18nLoaded, error: i18nError } = useI18nLoaded();
  const { i18nInitialized } = useI18nInitialized();

  const title = i18nLoaded && !i18nError && i18nInitialized ? i18n.t('global:global.page.page_title') : 'Dojo for business | App';
  const faviconUrl = addBasePath('/favicon.ico');
  const logoUrl = addBasePath('/dojo.png');

  return (
    <NextSeo
      title={title}
      //   description={description}
      additionalLinkTags={[
        { rel: 'icon', href: faviconUrl },
        { rel: 'apple-touch-icon', href: faviconUrl },
      ]}
      openGraph={{
        type: 'website',
        title: title,
        // description: description,
        locale: locale,
        images: [
          {
            url: logoUrl,
            alt: title,
          },
        ],
      }}
    />
  );
};

export default memo(Seo);
