import { memo } from 'react';
import { DjpUserProvider } from 'dj-pages-react';
import useAuth from '@/hooks/useAuth';

const UserTunnelProvider = ({ children }): JSX.Element => {
  const { user } = useAuth();

  const userProvider = {
    email: user?.email,
    uid: user?.dojoId as string,
  };
  return <DjpUserProvider user={userProvider}>{children}</DjpUserProvider>;
};

export default memo(UserTunnelProvider);
