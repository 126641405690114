import { memo } from 'react';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { ApiRoutes } from '@/utils/routes';
import ApolloProvider from './apollo';
import ClientSizeProvider from './clientSize';
import FeatureFlagsProvider from './featureFlags';
import I18nProvider from './i18n';
import InternalUserProvider from './internalUser';
import ApiConfigProvider from './apiConfig';
import AnalyticsProvider from './analytics';
import RouterProvider from './router';
import LinksProvider from './links/linksProvider';
import PhoneNumbersProvider from './phoneNumbers/phoneNumbersProvider';
import CustomersProvider from './customers';
import PermissionsProvider from './permissions';
import LoggerProvider from './logger';
import MarketProvider from './market';
import LaunchDarklyProvider from './launchdarkly';
import GraphqlErrorHandlerProvider from './graphqlErrorHandler';
import ProtectedPages from '@/components/protectedPages/protectedPages';
import AuthProvider from './auth';
import BrazeProvider from './braze';
import EligibleProductsProvider from './eligibleProducts';
import UserTunnelProvider from './user';
import IdleTimer from '@/components/idleTimer/idleTimer';
import PageHeaderProvider from './pageHeader';
import { InviteProvider } from './invite/InviteProvider';

const Providers = ({ nonce, children }): JSX.Element => (
  <LoggerProvider>
    <AnalyticsProvider>
      <UserProvider profileUrl={ApiRoutes.profile} loginUrl={ApiRoutes.login}>
        <AuthProvider>
          <UserTunnelProvider>
            <ApolloProvider>
              <I18nProvider>
                <RouterProvider>
                  <FeatureFlagsProvider>
                    <ApiConfigProvider>
                      <ClientSizeProvider>
                        <ProtectedPages noAuthChildren={children}>
                          <GraphqlErrorHandlerProvider>
                            <InternalUserProvider>
                              <InviteProvider>
                                <CustomersProvider>
                                  <MarketProvider>
                                    <LaunchDarklyProvider>
                                      <LinksProvider>
                                        <PhoneNumbersProvider>
                                          <PermissionsProvider>
                                            <BrazeProvider nonce={nonce}>
                                              <EligibleProductsProvider>
                                                <PageHeaderProvider>
                                                  <IdleTimer />
                                                  {children}
                                                </PageHeaderProvider>
                                              </EligibleProductsProvider>
                                            </BrazeProvider>
                                          </PermissionsProvider>
                                        </PhoneNumbersProvider>
                                      </LinksProvider>
                                    </LaunchDarklyProvider>
                                  </MarketProvider>
                                </CustomersProvider>
                              </InviteProvider>
                            </InternalUserProvider>
                          </GraphqlErrorHandlerProvider>
                        </ProtectedPages>
                      </ClientSizeProvider>
                    </ApiConfigProvider>
                  </FeatureFlagsProvider>
                </RouterProvider>
              </I18nProvider>
            </ApolloProvider>
          </UserTunnelProvider>
        </AuthProvider>
      </UserProvider>
    </AnalyticsProvider>
  </LoggerProvider>
);

export default memo(Providers);
