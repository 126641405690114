import { createContext, memo, useMemo } from 'react';
import { DjpAnalyticsProvider } from 'dj-pages-react';
import { AnalyticsExporter } from '../config/analyticsExporter';

export interface AnalyticsContext {
  analytics: AnalyticsExporter;
}

export const AnalyticsContext = createContext<AnalyticsContext>({
  analytics: null,
});

const AnalyticsProvider = ({ children }): JSX.Element => {
  const rudderStack = useMemo(() => new AnalyticsExporter(), []);
  const value = useMemo(
    () => ({
      analytics: rudderStack,
    }),
    [rudderStack]
  );

  return (
    <AnalyticsContext.Provider value={value}>
      <DjpAnalyticsProvider analytics={rudderStack}>{children}</DjpAnalyticsProvider>
    </AnalyticsContext.Provider>
  );
};

export default memo(AnalyticsProvider);
