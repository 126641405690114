import { memo, useCallback } from 'react';
import { DjpGraphqlErrorHandler } from 'dj-pages-react';
import { useIonToast } from '@ionic/react';
import { i18n } from '@paymentsense/dj-i18n';
import { useRouter } from 'next/navigation';

const GraphqlErrorHandlerProvider = ({ children }): JSX.Element => {
  const [present] = useIonToast();
  const router = useRouter();

  const handleUnauthorizedError = useCallback(
    async (message?) => {
      await present({
        buttons: [{ text: 'OK', role: 'cancel' }],
        message: message ?? i18n?.t('global:global.error.unauthorized') ?? `You tried to view a page you don't have permission to access.`,
        position: 'bottom',
      });

      // Refresh the page after 1s
      setTimeout(() => router.refresh(), 1000);
    },
    [present, router]
  );

  const notifyUserAndLog = useCallback(async (err, message?: string) => {
    // Placeholder method brought from angular, at the time not actually used in dj-pages
    // logger.exception(err);
    // await present({
    //   buttons: [{ text: 'OK', role: 'cancel' }],
    //   message,
    //   position: 'bottom',
    // });
    // return Promise.resolve({ loading: false, data: null, networkStatus: NetworkStatus.error } as ApolloQueryResult<T>);
    return Promise.resolve({ err, message });
  }, []);

  return <DjpGraphqlErrorHandler graphqlErrorHandler={{ handleUnauthorizedError, notifyUserAndLog } as any}>{children}</DjpGraphqlErrorHandler>;
};

export default memo(GraphqlErrorHandlerProvider);
